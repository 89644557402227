<template>
    <div class="card">
        <div class="card-body">
            <a-tabs default-active-key="1">
                <a-tab-pane key="1" tab="Información General" value="Información General" force-render>
                    <suppliersPartialsGeneral />
                </a-tab-pane>
                <a-tab-pane key="2" tab="Información Bancaria" value="Información Bancaria" force-render>
                    <suppliersPartialsBank />
                </a-tab-pane>
                <a-tab-pane key="3" tab="Información Fiscal" value="Información Fiscal" force-render>
                    <suppliersPartialsTax />
                </a-tab-pane>
                <a-tab-pane key="4" tab="Contactos" value="Contactos" force-render v-if="false">
                    <suppliersPartialsContacts />
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import suppliersPartialsGeneral from '@/components/suppliers/partials/general'
import suppliersPartialsBank from '@/components/suppliers/partials/bank'
import suppliersPartialsTax from '@/components/suppliers/partials/tax'
import suppliersPartialsContacts from '@/components/suppliers/partials/contacts'

export default {
    name: 'supplierDetailView',
    components: {
        suppliersPartialsGeneral,
        suppliersPartialsBank,
        suppliersPartialsTax,
        suppliersPartialsContacts,
    },
    computed: {
        ...mapGetters('suppliers', ['spinnerLoader', 'spinnerLoaderLabel', 'supplier_type']),
        ...mapGetters('auth', ['userData']),
    },
    mounted() {
        if (utilities.objectValidate(this.userData, 'id', false)) {
            this.$store.dispatch('suppliers/GET_ONE', this.userData.id)
        }
    },
}
</script>